.wrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  border-bottom: 1px solid #eaeaea;
  padding: var(--spacing-4) var(--spacing-4);
  gap: var(--spacing-6);
}

.schemaItem {
  display: flex;
  flex-direction: column;
  flex: 0 0 200px;
  gap: var(--spacing-3);
  align-items: center;
  justify-content: center;

  background: var(--color-neutral-50);
  padding: var(--spacing-4);
  cursor: pointer;
  transition: 0.1s;

  &:hover {
    background: var(--color-neutral-100);
  }
}
