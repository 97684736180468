.wrapper {
  max-width: 1312px;
  padding: var(--spacing-10) 0 var(--spacing-10) 0;
}

.contactDetails {
  align-items: flex-start;
  display: flex;
  flex-direction: row;
  gap: 85px;

  @media (--mobile) {
    align-items: center;
    flex-direction: column;
    gap: var(--spacing-4);
  }
}

.ctas {
  display: flex;
  flex-direction: row;
  gap: var(--spacing-4);
  margin-top: var(--spacing-4);
  flex: 1;

  & p {
    margin: 0;
  }

  @media (--mobile) {
    align-items: center;
    border-bottom: 1px solid var(--color-neutral-100);
    flex-direction: column;
    margin-bottom: var(--spacing-4);
    padding-bottom: var(--spacing-10);
    width: 100%;
  }
}

.iconCard,
.iconCard a {
  align-items: center;
  color: var(--color-neutral-700);
  display: flex;
  flex-direction: row;
  gap: var(--spacing-2);
}

.logoCard svg:nth-child(2) {
  height: 60px;
}

.logoCard,
.infoCard {
  gap: var(--spacing-4);
}

.infoCard {
  align-items: flex-start;
  margin-top: var(--spacing-4);
  margin-right: var(--spacing-16);

  & p {
    margin: 0;
  }

  @media (--mobile) {
    margin-bottom: var(--spacing-8);
  }
}

.footer {
  margin-top: var(--spacing-8);

  & p {
    font-size: 12px;
    margin: 0;
  }
}
