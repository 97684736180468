.reviewCardWrapper {
  background: var(--color-championship-white);
  cursor: pointer;
  display: flex;
  flex-flow: column wrap;
  justify-content: space-between;
  height: 100%;
  max-width: 340px;
  overflow: hidden;
  gap: var(--spacing-2);
  margin-bottom: var(--spacing-4);
}

.reviewCardImage {
  position: relative;
  height: 0;
  padding-bottom: 66.71%;
  max-width: 340px;

  img {
    width: 100%;
    max-width: 340px;
    max-height: 227px;
    object-fit: cover;
  }
}

.reviewCardRatingGauge {
  position: relative;
}

.reviewCardRatingGauge > * {
  position: absolute;
  right: var(--spacing-2);
  margin-top: -70px;
}

.titleWrapper {
  padding: 0 var(--spacing-4) var(--spacing-4);
}

.title {
  font-size: 18px;
  margin-bottom: 0;
}

.quote {
  margin-top: var(--spacing-2);
  margin-bottom: 0;
}

.reviewCardCtas {
  display: flex;
  flex-flow: column wrap;
  gap: var(--spacing-2);

  & > a > span {
    white-space: normal;
    text-align: center;
  }
}
