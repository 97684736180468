@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.dealerButtonsWrapper {
  display: flex;
  flex-direction: column;
  row-gap: 10px;
}

.minHeightZero {
  min-height: 0;
}

.leadButtonsOuter {
  min-height: 68px;

  &.btnNum1 {
    min-height: 68px;
  }
  &.btnNum2 {
    min-height: 128px;
  }
  &.btnNum3 {
    min-height: 188px;
  }
  &.btnNum4 {
    min-height: 248px;
  }
  &.btnNum5 {
    min-height: 308px;
  }

  @media (--tablet) {
    min-height: 0;

    &.btnNum1,
    &.btnNum2,
    &.btnNum3,
    &.btnNum4,
    &.btnNum5 {
      min-height: 0;
    }
  }
}

.leadButtonsWrapper {
  display: grid;
  gap: var(--spacing-3);
  margin-top: var(--spacing-5);

  &.stickyContent {
    animation-duration: 0.3s;
    animation-name: fadeIn;
  }

  @media (--tablet) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (--tablet-l) {
    margin-top: var(--spacing-7);
    grid-template: none;
    margin-bottom: unset;
  }
}

.leadButtonsWrapper.hasReserveNow {
  @media (min-width: 768px) and (max-width: 1024px) {
    grid-template-columns: repeat(3, 1fr);
  }
}

.stickyContent {
  position: fixed;
  width: 100%;
  background-color: white;
  box-shadow: 0 0 13px 0 rgba(38, 38, 38, 0.14);
  bottom: 0;
  display: flex;
  z-index: 9999;
  left: 0;
  padding: var(--spacing-3);
  overflow-x: scroll;

  [data-has-footer-sticky='true'] & {
    bottom: 52px;
    @media (--tablet-l) {
      bottom: 0;
    }
  }

  & button {
    flex: 1 1 50%;
  }
  & .teamedWith {
    display: none;
  }
  [data-service-consumer='native'] & {
    bottom: 0;
    padding: var(--spacing-5);
  }
}

.priorityCTA {
  @media (--mobile) {
    order: 2;
  }
}

.stickyCta {
  margin-top: 0;
  padding: var(--spacing-5) 0 var(--spacing-4) 0;
}

/* only for mobile viewports */
.vspAndNoneVspMobileCTAWrapper {
  & button {
    @media (--mobile) {
      flex: 1 1 20%;
    }
    &:first-child {
      @media (--mobile) {
        flex: 1 1 60%;
      }
    }
  }
}

.reserveNowWrapper {
  margin: var(--spacing-5) 0 0 0;
}

.reserveNowCtaDesktop {
  display: none;

  @media (--tablet) {
    display: inherit;
  }
}

.reserveNowCtaMobile {
  display: inherit;

  @media (--tablet) {
    display: none;
  }
}

.teamedWith {
  display: flex;
  align-items: flex-end;
  gap: var(--spacing-2);
  margin: 0;
  justify-content: flex-start;

  & svg {
    height: 100%;
    max-width: 33%;
  }
  & span {
    line-height: 19px;
  }

  @media (--tablet-s) {
    grid-column: 1 / 3;
  }
  @media (--tablet-l) {
    grid-column: unset;
  }
}

.vspDesktopLayout {
  @media (--tablet-s) {
    grid-template-columns: 1fr 1fr;

    & button:first-child {
      grid-column: span 2;
    }
  }
}
