.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  opacity: 0;
  visibility: hidden;
  transition: 0.3s;
  backdrop-filter: blur(4px) brightness(90%) contrast(110%);

  pointer-events: none;
  contain: strict;

  &[data-is-static='true'] {
    backdrop-filter: blur(20px) brightness(90%) contrast(110%);
    position: static;
    padding: var(--spacing-20) 0;
    contain: initial;
    background: none;

    @media (--mobile) {
      padding: 0;
    }

    & .container {
      position: static;
      transform: none;
      max-height: none;
      overflow: visible;
    }
  }

  &[data-visible='true'] {
    opacity: 1;
    visibility: visible;
    pointer-events: all;

    :is(body, html):has(&) {
      overflow: clip;
      position: fixed;
      width: 100vw;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
    }
  }

  &[data-visible='true'] .container {
    transform: translateY(0);
    opacity: 1;
  }

  [data-is-not-modal='true'] & {
    background-color: transparent;
    backdrop-filter: none;
    position: static;
    width: 100%;
    height: auto;
    opacity: 1;
    visibility: visible;
    pointer-events: all;
    contain: initial;
  }

  body:has([data-is-login-page]) & {
    height: 100vh;
    background: white;

    &[data-account-form-id='login'] .closeButton {
      display: none;
    }
  }
}

.container {
  background-color: var(--color-championship-white);
  width: 100%;
  max-width: 463px;
  transform: scale(0.95);
  opacity: 0;
  transition: 0.3s;
  /* overflow: auto; */
  max-height: 90vh;
  display: flex;
  flex-direction: column;

  @media (--mobile) {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    max-width: initial;
    transform: translateY(20px);
    max-height: 100vh;
  }

  [data-is-not-modal='true'] & {
    background-color: transparent;
    backdrop-filter: none;
    position: static;
    width: 100%;
    height: auto;
    opacity: 1;
    visibility: visible;
    pointer-events: all;
  }

  body:has([data-is-login-page]) & {
    height: 100vh;
  }
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 var(--spacing-10);
  border-bottom: 1px solid var(--color-tarmac-grey-50);
  height: 64px;
  font-size: 20px;
  flex: 0 0 64px;

  & h5 {
    line-height: 1;
    padding: 0;
    margin: 0;
    font-size: 20px;
  }
}

.closeButton {
  background: none;
  border: none;
  cursor: pointer;
  margin: 0;
  padding: 0;

  [data-is-not-modal='true'] & {
    display: none;
  }
}

.content {
  padding: var(--spacing-8) var(--spacing-10) var(--spacing-12);
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: var(--spacing-4);
  flex: 1 1 auto;
  overflow: scroll;

  & p,
  & h4,
  & h5,
  & h3 {
    margin: 0;
  }
}

.icon {
  width: 75px;
  height: 75px;
  background-color: var(--color-mica-blue-100);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.form {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: var(--spacing-4);
}

.divider {
  width: 100%;
  text-align: center;
  border-bottom: 1px solid var(--color-tarmac-grey-200);
  line-height: 0.1em;
  margin: var(--spacing-4) 0;
}

.divider span {
  background: var(--color-championship-white);
  padding: 0 var(--spacing-4);
  /* color: var(--color-tarmac-grey-500); */
  font-weight: 600;
}

.container button {
  justify-content: center;
}

.container button img {
  margin-right: var(--spacing-2);
}

.buttons {
  display: flex;
  flex-direction: column;
  width: 100%;

  gap: var(--spacing-5);

  color: var(--color-tarmac-grey-700);
  fill: var(--color-tarmac-grey-700);

  & svg {
    color: var(--color-tarmac-grey-700);
    fill: var(--color-tarmac-grey-700);
  }

  & u {
    cursor: pointer;
  }

  & button span {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: var(--spacing-1);
    flex-direction: row;

    &:hover {
      background-color: var(--color-tarmac-grey-50);
    }
  }
}

.switch {
  & span {
    color: var(--color-primary-500);
    text-decoration: underline;
    cursor: pointer;
  }
}

.tAndC {
  & span,
  & a {
    color: var(--color-tarmac-grey-700);
    text-decoration: underline;
    cursor: pointer;

    &:hover {
      color: var(--color-primary-500);
    }
  }
}

.forgot {
  font-size: 14px;
  line-height: 1;
  cursor: pointer;
  text-decoration: underline;
  margin-top: calc(var(--spacing-2) * -1);
  margin-bottom: var(--spacing-1);
  align-self: flex-end;

  &:hover {
    color: var(--color-primary-500);
  }
}
