.video {
  margin: 0 calc(var(--spacing-5) * -1);
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  overflow: hidden;
  min-width: 100%;

  & .overlay {
    border: none;
    margin: 0;
    padding: 0;
    width: auto;
    overflow: visible;
    background: transparent;
    color: inherit;
    font: inherit;
    line-height: normal;
    cursor: pointer;
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    top: 0;

    & img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
    }

    &:hover {
      & span {
        opacity: 1;
      }
    }

    & span {
      position: absolute;
      left: 50%;
      top: 50%;
      width: 68px;
      height: 48px;
      margin-left: -34px;
      margin-top: -24px;
      opacity: 0.6;
      transition: opacity 0.25s cubic-bezier(0, 0, 0.2, 1);
    }
  }

  & iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0;
  }
}

@media (--tablet) {
  .video {
    margin: 0 calc(var(--spacing-6) * -1);
  }
}

@media (--tablet-l) {
  .video {
    margin: 0 calc(var(--spacing-8) * -1);
  }
}

@media (--desktop) {
  .video {
    width: 100%;
    margin: unset;
  }
}
