.attributeWrapperSidebar {
  margin-top: var(--spacing-5);
  display: flex;
  flex-direction: column;

  @media (--tablet) {
    margin-top: var(--spacing-8);
  }

  @media (--tablet-l) {
    margin-top: 0;
  }

  &[data-scrolled='true'] {
    display: flex;
    place-items: baseline;
    gap: 7px;

    .totalPrice {
      margin-top: 0;
    }
  }
}

.vehiclePrettyName,
.vehicleVariantName {
  color: var(--color-neutral-700);
  margin: 0;
}

.vehicleVariantName {
  @media (--mobile) {
    font-size: 14px;
  }
}

.makeModelNameWrapper {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: var(--spacing-2);
}

.priceWrapper {
  margin-top: var(--spacing-5);
  flex-grow: 1;
  @media (--tablet-l) {
    margin-top: var(--spacing-7);
  }

  & .priceAttributes {
    display: flex;
  }
}

.financeDetails {
  margin-top: var(--spacing-3);
  display: flex;
  align-items: center;

  @media (--tablet-l) {
    margin-top: var(--spacing-5);
  }
  & span {
    margin-right: var(--spacing-1);
    text-decoration: underline;
  }
}

.depositContribution {
  display: inline-block;
  background-color: var(--color-heycar-mint-50);
  line-height: 28px;
  margin-top: var(--spacing-2);
}

.scrollWrapper {
  display: block;
  &[data-scrolled='true'] {
    place-items: baseline;

    .totalPrice {
      margin-top: 20px;
    }
  }
}

.discountAndScrolled {
  @media (--tablet-l) {
    margin-top: -20px;
  }
}

.smallPrint {
  text-align: start;
  margin-top: var(--spacing-3);
  @media (--tablet) {
    max-width: 50%;
  }
  @media (--tablet-l) {
    margin-top: var(--spacing-4);
    max-width: 100%;
  }
  font-size: 10px !important;
  & > span {
    & > * {
      font-size: 10px !important;
    }
  }
}

.monthlyPrice {
  margin-left: var(--spacing-1);
}

.contact {
  display: flex;
  align-items: center;
  margin-top: var(--spacing-3);

  & .logo {
    display: flex;
    flex: 1;
    align-items: center;
    margin-left: var(--spacing-1);
  }

  & svg {
    height: 100%;
    margin-left: var(--spacing-1);
    max-width: 40%;
    position: relative;
    top: 2px;
  }
}
