.container {
  display: flex;
  flex-direction: column;
  gap: 0 var(--spacing-10);
  margin-bottom: var(--spacing-10);
  align-items: space-between;
  justify-content: space-between;
  width: 100%;

  @media (--tablet-l) {
    flex-direction: row;
  }
}

.wrapper {
  display: flex;
  flex-direction: column;
  min-height: 700px;
  position: relative;
  gap: 64px 0;
  @media (--tablet-l) {
    width: 100%;
    gap: 80px 0;
  }
}
.bodyText {
  @media (--tablet-l) {
    max-width: 594px;
  }
  @media (--desktop-l) {
    max-width: 868px;
  }
}

.ctaContainer {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-4);
}

.ctasWrapper {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-3);

  @media (--tablet) {
    flex-direction: row;
    gap: var(--spacing-6);
  }
}

.button {
  width: 100%;
  @media (--tablet) {
    width: 348px;
  }
  @media (--desktop-l) {
    width: 421px;
  }
}

.footer {
  line-height: 26px;
}
.offerGuide {
  display: flex;
  width: 100%;
  line-height: 26px;
  @media (--tablet) {
    width: 80%;
  }
}

.leasingGuideWrapper {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: var(--spacing-2);
}

.leasingGuide {
  margin: 0;
}
