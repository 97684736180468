.sidebar {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-3);

  flex: 0 0 250px;
  background-color: #f7f7f7;
  border-right: 1px solid #f0f0f0;
  padding: var(--spacing-8);
}

.schema {
  cursor: pointer;
  transition: 0.1s;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: var(--spacing-2);
  color: var(--color-neutral-600);

  &:hover {
    color: var(--color-primary-500);
  }
}
