[data-pdp-breadcrumb].backButton {
  &&& {
    color: inherit;
    text-decoration: unset;
    justify-content: flex-start;
    min-height: var(--button-large-height);

    @media (--tablet-l) {
      margin-bottom: -32px;
    }

    @media (--desktop-l) {
      margin-bottom: -64px;
    }
  }
}
