.wrapper {
  height: 64px;

  @media (--tablet-l) {
    height: 72px;
  }

  @media (--desktop-l) {
    height: 80px;
  }

  & .header {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 100;
    overflow: hidden;
    width: 100%;
    max-width: 100vw;
    height: inherit;
    background: var(--header-nav-background-color);

    [data-has-footer-sticky='true'] & {
      position: sticky;
      z-index: 99;
      @media (--tablet-l) {
        position: fixed;
      }
    }

    @media (--tablet-l) {
      overflow: visible;
    }

    @media (--desktop) {
      background: var(--header-nav-background-color);
    }

    & .headerInner {
      padding-top: var(--spacing-4);
      padding-bottom: var(--spacing-4);
      width: 100%;
      height: inherit;
      background: var(--header-nav-background-color);

      & > div {
        width: 100%;
      }
      & .colLeft {
        display: flex;
        gap: var(--spacing-12);
        align-items: center;
        @media (--tablet-l) {
          height: 48px;
        }
        & .logo {
          display: inline-block;
          height: 32px;

          & > svg {
            width: auto;
            height: 32px;
          }

          @media (--desktop) {
            margin-left: var(--spacing-3);
          }
        }
      }
      & .colRight {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        @media (--desktop) {
          gap: var(--spacing-1);
        }
      }
    }
  }
}
