.wrapper {
  position: relative;
  height: 0;
  overflow: hidden;
  padding-bottom: calc(1 / var(--ratio-mobile) * 100%);

  & img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }
}
@media (--tablet) {
  .wrapper {
    padding-bottom: calc(1 / var(--ratio-tablet) * 100%);
  }
}
@media (--desktop) {
  .wrapper {
    padding-bottom: calc(1 / var(--ratio-desktop) * 100%);
  }
}
