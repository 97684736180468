.layout {
  display: flex;
  flex-direction: row;
  /* flex-wrap: wrap; */
  height: 100vh;
  max-height: 100vh;

  & h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p {
    padding: 0;
    margin: 0;
  }
}

.content {
  display: flex;
  flex-direction: column;
  flex: 1 1 1px;
  max-height: 100vh;
  max-width: calc(100vw - 250px);
}

.versions {
  display: flex;
  flex-direction: row;
  gap: var(--spacing-3);

  justify-content: flex-start;
}

.version {
  padding: 0 var(--spacing-3);
}
