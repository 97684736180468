.wrapper {
  flex-direction: column;
  gap: var(--spacing-4);
  margin-top: 0;
  transition: 0.3s ease-in-out opacity;

  @media (--tablet-l) {
    display: flex;
  }

  &.isMobile {
    display: flex;
    box-shadow: 0px 6px 8px rgba(0, 0, 0, 0.08);
    margin: var(--spacing-6) 0;
    width: 100vw;
    position: relative;
    padding: 0 var(--spacing-5) var(--spacing-5);
    left: -20px;

    &.isShadowVisible {
      box-shadow: none;
      margin-bottom: 0;
    }

    @media (--tablet-l) {
      display: none;
    }
  }
}

.vehicleInfo {
  display: flex;
  flex-direction: row;
  gap: var(--spacing-3);
  min-height: 109px;

  @media (--mobile) {
    display: none;
  }

  &.isMobile {
    @media (--mobile) {
      display: flex;
      min-height: unset;
    }
  }
}

.vehicleImage {
  height: 109px;
  aspect-ratio: 4/3;
  object-fit: cover;
  border-radius: var(--border-radius-2);

  @media (--mobile) {
    height: 65px;
  }
}

.vehicleDetails {
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: space-between;

  & * {
    margin: 0;
    padding: 0;
  }

  @media (--mobile) {
    width: 100%;
  }
}

.vehiclePrice {
  display: flex;
  justify-content: space-between;
  font-size: 28px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: -0.5px;
  width: 100%;

  @media (--mobile) {
    font-size: 16px;
  }

  & .externalLink {
    cursor: pointer;
  }
}

.carDetails {
  line-height: normal;
}

.carAttributes {
  @media (--mobile) {
    display: none;
  }
}

.deal {
  background-color: var(--color-heycar-mint-50);
  display: none;
  flex-direction: row;
  padding: var(--spacing-3);
  gap: var(--spacing-1);

  & svg {
    margin-right: var(--spacing-1);
  }

  & p {
    margin: 0;
  }

  @media (--tablet-l) {
    display: flex;
  }
}

.financeBreakdown {
  margin: 0;

  &.hideOnMobile {
    @media (--mobile) {
      display: none;
    }
  }

  & h4 {
    font-size: 20px;
    margin: 0;
  }

  & .line {
    display: flex;
    justify-content: space-between;

    & p {
      margin: 0;
    }

    &:not(:first-child) {
      margin-top: var(--spacing-2);
    }
  }
}

.contact {
  align-items: center;
  border: 1px solid var(--color-primary-100);
  display: flex;
  gap: var(--spacing-4);
  padding: var(--spacing-8);

  @media (--mobile) {
    justify-content: center;
    padding: var(--spacing-3);

    &:not(.isAdviceVisible) {
      display: none;
    }
  }

  & .icon {
    align-items: center;
    border: 1px solid var(--color-primary-500);
    border-radius: 50%;
    display: flex;
    justify-content: center;
    height: 50px;
    width: 50px;

    @media (--mobile) {
      height: 50px;
      width: 50px;

      & svg {
        height: 24px;
        width: 24px;
      }
    }

    &.isMobile {
      @media (--mobile) {
        height: 30px;
        width: 30px;

        & svg {
          height: 18px;
          width: 18px;
        }
      }
    }

    & svg {
      color: var(--color-primary-500);
    }
  }

  & .title,
  & .info {
    align-items: center;
    display: flex;
    gap: var(--spacing-1);

    & p {
      margin: 0;
    }

    & span {
      color: var(--color-primary-500);
    }
  }

  & .title span {
    cursor: pointer;
  }

  & .info caption {
    color: var(--color-neutral-500);
  }

  & .info {
    align-items: center;

    @media (--mobile) {
      flex-direction: column;
      align-items: flex-start;
    }

    &.isMobile {
      @media (--mobile) {
        display: none;
      }
    }

    & .workingHours {
      display: flex;
      flex-direction: column;
      gap: var(--spacing-1);
    }
  }
}

.modalBody {
  @media (--tablet) {
    max-height: 90vh;
    overflow-y: scroll;
  }
}

.offerInfo {
  @media (--mobile) {
    display: none;
  }

  &[data-is-mobile='true'] {
    @media (--mobile) {
      display: block;
    }
  }
}
